import type { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import type { UnapproveCorrectionMutation } from '~/components/correction/CorrectingUsers/graphql/UnapproveCorrectionMutation.generated';
import type { MutationDeleteCorrectionApprovalArgs } from '~/types/graphql';
import { CorrectionCacheUpdateFragmentDoc } from './graphql/CorrectionCacheUpdateFragment.generated';

/**
 * urql Mutation Update Cache resolver for deleteCorrectionApprovals
 */
export function deleteCorrectionApprovalUpdate(
    result: UnapproveCorrectionMutation,
    args: MutationDeleteCorrectionApprovalArgs,
    cache: Cache,
    info: ResolveInfo
) {
    if (!result?.deleteCorrectionApproval) {
        return;
    }

    // CorrectionId is an extra variable passed for correct cache update and not part of the query.
    // If not passed as part of the useMutation fetch function call, this resolve here does nothing.
    const correctionId: string = info.variables?.correctionId as
        | string
        | undefined;

    if (!correctionId) {
        return;
    }

    const correction = cache.readFragment(CorrectionCacheUpdateFragmentDoc, {
        __typename: 'Correction',
        id: correctionId,
    });

    if (!correction) {
        return;
    }

    const newApprovalList = correction.correctionApprovals.filter(
        (approval) => approval.id !== args.input.id
    );

    cache.writeFragment(CorrectionCacheUpdateFragmentDoc, {
        ...correction,
        correctionApprovals: newApprovalList,
    });
}
