export function sortBlocksByPosition<T extends { position: number }[]>(
    blocks: T
) {
    // This should either go to a local urql resolver, or in best
    // case, be done by the backend already. As the urql resolver
    // solution wasn't simple enough to accomplish, we use this centralized hook here.
    return [...blocks]
        .filter((block) => block)
        .sort((a, b) => {
            if (a.position === b.position) {
                console.error(
                    `There are two blocks with the same position. Skipping sorting. This shouldn't happen and is most likely an issue with your reordering functionality.`
                );
            }

            return a.position - b.position;
        });
}
