import type * as Types from '../../../../../types/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExamAuthorQueryVariables = Types.Exact<{
  examId: Types.Scalars['ID']['input'];
}>;


export type ExamAuthorQuery = { __typename: 'Query', exam?: { __typename: 'Exam', id: string, createdAt?: Date | null, updatedAt?: Date | null, archivedAt?: Date | null, autoArchiveWarned: boolean, autoArchiveDate?: Date | null, createdBy?: { __typename: 'User', id: string, username: string } | null, updatedBy?: { __typename: 'User', id: string, username: string } | null, archivedBy?: { __typename: 'User', id: string, username: string } | null } | null };

export type ExamAuthorExtendAutoArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExamAuthorExtendAutoArchiveMutation = { __typename: 'Mutation', extendAutoArchiveExam?: { __typename: 'extendAutoArchiveExamPayload', exam?: { __typename: 'Exam', id: string, autoArchiveWarned: boolean, autoArchiveDate?: Date | null } | null } | null };


export const ExamAuthorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExamAuthor"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"examId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"examId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"archivedBy"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}},{"kind":"Field","name":{"kind":"Name","value":"archivedAt"}},{"kind":"Field","name":{"kind":"Name","value":"autoArchiveWarned"}},{"kind":"Field","name":{"kind":"Name","value":"autoArchiveDate"}}]}}]}}]} as unknown as DocumentNode<ExamAuthorQuery, ExamAuthorQueryVariables>;
export const ExamAuthorExtendAutoArchiveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ExamAuthorExtendAutoArchive"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"extendAutoArchiveExam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exam"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"autoArchiveWarned"}},{"kind":"Field","name":{"kind":"Name","value":"autoArchiveDate"}}]}}]}}]}}]} as unknown as DocumentNode<ExamAuthorExtendAutoArchiveMutation, ExamAuthorExtendAutoArchiveMutationVariables>;