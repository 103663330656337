import { useQuery } from 'urql';
import { CurrentUserFavouritesCountDocument } from './useCurrentUserFavouritesCount.generated';
import { useContext } from 'react';
import { TeacherContext } from '~/context/TeacherContext';
import { useUserFavouritesCount } from './useUserFavouritesCount';
import type { ExamType } from '~/routes/dashboard/Exams';

interface CurrentUserHasFavouritesProps {
    filter?: {
        examType?: ExamType;
    };
}

type CurrentUserFavouritesCount = number;
type FavouritesFetching = boolean;

/**
 * React Hook to check for existence of favourites for the current user.
 * @returns Tuble with two values:
 */
export function useCurrentUserFavouritesCount({
    filter,
}: CurrentUserHasFavouritesProps = {}): [
    CurrentUserFavouritesCount,
    FavouritesFetching
] {
    const isTeacherView = useContext(TeacherContext);

    const [result] = useQuery({
        query: CurrentUserFavouritesCountDocument,
        variables: {},
        requestPolicy: 'cache-first',
    });

    const fetching = result?.fetching || result?.stale;

    const currentUser = result?.data?.currentUser;
    const currentUserFavouritesCount = useUserFavouritesCount({
        user: currentUser,
        filter: {
            examType: filter?.examType,
            exclude: isTeacherView ? ['passes'] : null,
            include: !isTeacherView ? ['passes'] : null,
        },
    });

    return [currentUserFavouritesCount, fetching];
}
