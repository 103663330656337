import ReactDOM from 'react-dom';
import React from 'react';
import { App } from '~/components/App';

const root = document.getElementById('root')!;
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    root
);
