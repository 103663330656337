declare global {
    interface Window {
        SafeExamBrowser: {
            security: {
                configKey: string;
                browserExamKey: string;
            };
        };
    }
}

/**
 * Memomized first API request origin inside seb browser.
 * Used for continuous requests to match with configKey.
 */
let sebRequestedFromMemo: string = null;

export const isSafeExamBrowser = (): boolean => {
    return typeof window?.SafeExamBrowser?.security?.configKey !== 'undefined';
};

export const getSafeExamBrowserHttpHeaders = () => {
    const headers = {};

    if (isSafeExamBrowser()) {
        // configKey is created based on the URL, but not updated during navigation inside
        // the SPA without a hard-page refresh. Therefore we also safe the url of our
        // first requests to make the behaviour between cacheKey and request url match
        // and the validation in the backend to pass.
        sebRequestedFromMemo = sebRequestedFromMemo ?? window.location?.href;

        headers['X-smartlearn-seb-config-key'] =
            window.SafeExamBrowser.security.configKey;
        headers['X-smartlearn-seb-browser-exam-key'] =
            window.SafeExamBrowser.security.browserExamKey;
        headers['X-smartlearn-seb-requested-from'] = sebRequestedFromMemo;
    }

    return headers;
};

interface PassSebRequiredProps {
    sebRequired?: boolean;
    event: {
        sebRequired: boolean;
    };
}

export const isSebRequired = (pass: PassSebRequiredProps): boolean => {
    return isSebRequiredActiveOnPass(pass) && !isSafeExamBrowser();
};

const isSebRequiredActiveOnPass = (pass: PassSebRequiredProps): boolean => {
    if (pass.sebRequired) return true;
    return pass.sebRequired === null && pass.event?.sebRequired;
};
