import { createStore, EasyPeasyConfig } from 'easy-peasy';
import storeModel from './model';

export const storeConfig: EasyPeasyConfig = {
    name: 'smartlearn',
    version: 1,
};

export const store = createStore(storeModel, storeConfig);

/**
 * @deprecated Use named import instead
 */
export default store;
