import type { Cache } from '@urql/exchange-graphcache';
import type { ApproveCorrectionMutation } from '~/components/correction/CorrectingUsers/graphql/ApproveCorrectionMutation.generated';
import type { MutationCreateCorrectionApprovalArgs } from '~/types/graphql';
import { CorrectionCacheUpdateFragmentDoc } from './graphql/CorrectionCacheUpdateFragment.generated';

/**
 * urql Mutation Update Cache resolver for creatingCorrectionApprovals
 */
export function createCorrectionApprovalUpdate(
    result: ApproveCorrectionMutation,
    args: MutationCreateCorrectionApprovalArgs,
    cache: Cache
) {
    if (!result?.createCorrectionApproval?.correctionApproval) {
        return;
    }

    const correctionId = args.input.correction;
    const newCorrectionApproval =
        result.createCorrectionApproval.correctionApproval;

    const correction = cache.readFragment(CorrectionCacheUpdateFragmentDoc, {
        __typename: 'Correction',
        id: correctionId,
    });

    if (
        !correction ||
        correction.correctionApprovals.some(
            (approval) =>
                approval.createdBy.id === newCorrectionApproval.createdBy.id
        )
    ) {
        return;
    }

    cache.writeFragment(CorrectionCacheUpdateFragmentDoc, {
        ...correction,
        correctionApprovals: [
            ...correction.correctionApprovals,
            newCorrectionApproval,
        ],
    });
}
