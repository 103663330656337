import {
    createBrowserRouter,
    RouterProvider as ReactRouterRouterProvider,
} from 'react-router-dom';
import { DynamicFavouriteIndex } from './dashboard/DynamicFavouriteIndex';
import { ErrorPage } from './ErrorPage';

const UrlSegments = {
    /**
     * Edit URL segment, used to indicate that the user is in edit mode.
     * Meant to be appended to the end of a URL.
     * @example `/detail-view/edit?`
     */
    edit: `edit`,
} as const;

export function RouterProvider() {
    const router = createBrowserRouter([
        {
            path: 'login',
            async lazy() {
                const { LoginPage } = await import('./LoginPage');

                return { Component: LoginPage };
            },
        },
        {
            path: '/',
            errorElement: <ErrorPage />,
            async lazy() {
                const { Root } = await import('./Root');

                return { Component: Root };
            },
            handle: {
                crumb: {
                    label: 'smartlearn',
                },
            },
            children: [
                // Entry point routes for authors, teachers and students
                {
                    async lazy() {
                        const { Overview } = await import(
                            './dashboard/Overview'
                        );

                        return { Component: Overview };
                    },
                    children: [
                        {
                            index: true,
                            element: (
                                <DynamicFavouriteIndex
                                    favouriteRoute="/favourites"
                                    fallbackRoute="/events"
                                />
                            ),
                        },
                        {
                            path: 'events',
                            async lazy() {
                                const { AllEvents } = await import(
                                    './dashboard/AllEvents'
                                );

                                return { Component: AllEvents };
                            },
                        },
                        {
                            path: 'favourites',
                            async lazy() {
                                const { OverviewFavorites } = await import(
                                    './dashboard/OverviewFavourites'
                                );

                                return { Component: OverviewFavorites };
                            },
                        },
                    ],
                },
                // Dashboard routes for authors, teachers and students
                {
                    path: 'exams',
                    async lazy() {
                        const { Exams } = await import('./dashboard/Exams');

                        return { Component: Exams };
                    },
                    children: [
                        {
                            index: true,
                            element: (
                                <DynamicFavouriteIndex
                                    favouriteRoute="/exams/favourites"
                                    fallbackRoute="/exams/events"
                                />
                            ),
                        },
                        {
                            path: 'events',
                            async lazy() {
                                const { Events } = await import(
                                    './dashboard/Events'
                                );

                                return { Component: Events };
                            },
                        },
                        {
                            path: 'variants',
                            async lazy() {
                                const { Variants } = await import(
                                    './dashboard/Variants'
                                );

                                return { Component: Variants };
                            },
                        },
                        {
                            path: 'pilots',
                            async lazy() {
                                const { Pilots } = await import(
                                    './dashboard/Pilots'
                                );

                                return { Component: Pilots };
                            },
                        },
                        {
                            path: 'favourites',
                            async lazy() {
                                const { Favorites } = await import(
                                    './dashboard/Favourites'
                                );

                                return { Component: Favorites };
                            },
                        },
                    ],
                },
                // Routes for the teachers to manage exam events (whose results are exam passes for the students)
                {
                    id: 'eventPage',
                    path: 'exams/:examId/events/:eventId',
                    async lazy() {
                        const { EventPage } = await import(
                            './events/EventPage'
                        );

                        return { Component: EventPage };
                    },
                    errorElement: <ErrorPage />,
                    children: [
                        {
                            id: 'eventCockpitPage',
                            index: true,
                            async lazy() {
                                const { EventCockpit } = await import(
                                    '~/components/cockpit/EventCockpit'
                                );

                                return { Component: EventCockpit };
                            },
                            errorElement: <ErrorPage />,
                        },
                        {
                            id: 'eventEnvironmentsPage',
                            path: 'environments',
                            errorElement: <ErrorPage />,
                            async lazy() {
                                const { EventEnvironmentsPage } = await import(
                                    './events/environments/EventEnvironmentsPage'
                                );

                                return { Component: EventEnvironmentsPage };
                            },
                        },
                        {
                            id: 'eventCorrectionPage',
                            path: 'correction',
                            errorElement: <ErrorPage />,
                            async lazy() {
                                const { EventCorrectionPage } = await import(
                                    './events/correction/EventCorrectionPage'
                                );

                                return { Component: EventCorrectionPage };
                            },
                            children: [
                                {
                                    id: 'eventCorrectionAnswersPage',
                                    path: 'answers',
                                    errorElement: <ErrorPage />,
                                    async lazy() {
                                        const {
                                            EventCorrectionAnswersPage,
                                        } = await import(
                                            './events/correction/EventCorrectionAnswersPage'
                                        );

                                        return {
                                            Component: EventCorrectionAnswersPage,
                                        };
                                    },
                                },
                                {
                                    id: 'eventCorrectionCandidatesPage',
                                    path: 'candidates',
                                    async lazy() {
                                        const {
                                            EventCorrectionCandidatesPageLoader,
                                        } = await import(
                                            '~/lib/router/loaders/EventCorrectionCandidatesPageLoader'
                                        );

                                        return {
                                            loader: EventCorrectionCandidatesPageLoader,
                                        };
                                    },
                                    children: [
                                        {
                                            index: true,
                                            errorElement: <ErrorPage />,
                                            async lazy() {
                                                const {
                                                    EventCorrectionCandidatesPage,
                                                } = await import(
                                                    './events/correction/EventCorrectionCandidatesPage'
                                                );

                                                return {
                                                    Component: EventCorrectionCandidatesPage,
                                                };
                                            },
                                        },
                                        {
                                            path: 'pass/:examPassId',
                                            errorElement: <ErrorPage />,
                                            async lazy() {
                                                const {
                                                    EventCorrectionCandidatesPage,
                                                } = await import(
                                                    './events/correction/EventCorrectionCandidatesPage'
                                                );

                                                return {
                                                    Component: EventCorrectionCandidatesPage,
                                                };
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'eventResultsPage',
                            path: 'results',
                            errorElement: <ErrorPage />,
                            async lazy() {
                                const { EventResultsPage } = await import(
                                    './events/EventResultsPage'
                                );

                                return { Component: EventResultsPage };
                            },
                        },
                    ],
                },
                // Routes for the authors and teacher to create and manage exam variants and pilots
                {
                    id: 'examDetails',
                    path: 'exams/:examId',
                    async lazy() {
                        const [
                            { ExamDetailsPage },
                            { ExamDetailsPageLoader },
                        ] = await Promise.all([
                            import('./exams/ExamDetailsPage'),
                            import(
                                '~/lib/router/loaders/ExamDetailsPageLoader'
                            ),
                        ]);

                        return {
                            loader: ExamDetailsPageLoader,
                            Component: ExamDetailsPage,
                        };
                    },
                    children: [
                        {
                            id: 'examDetailsGeneral',
                            path: `general/${UrlSegments.edit}?`,
                            errorElement: <ErrorPage />,
                            async lazy() {
                                const { ExamDetailsGeneralPage } = await import(
                                    './exams/general/ExamDetailsGeneralPage'
                                );

                                return {
                                    Component: ExamDetailsGeneralPage,
                                };
                            },
                        },
                        {
                            id: 'examDetailsVirtualEnvironments',
                            errorElement: <ErrorPage />,
                            path: `virtual-environments/${UrlSegments.edit}?`,
                            async lazy() {
                                // TODO: Refactor based on the example of the `examDetailsGeneral` route, by moving
                                // the logic into a "Page"
                                const { VirtualEnvironment } = await import(
                                    '~/components/teacher/InnerPage/VirtualEnvironment/VirtualEnvironment'
                                );

                                return {
                                    Component: VirtualEnvironment,
                                };
                            },
                        },
                        {
                            id: 'examDetailsAssignments',
                            errorElement: <ErrorPage />,
                            path: `assignments/${UrlSegments.edit}?`,
                            async lazy() {
                                // TODO: Refactor based on the example of the `examDetailsGeneral` route, by moving
                                // the logic into a "Page"
                                const { Assignments } = await import(
                                    '~/components/teacher/InnerPage/Assignments/Assignments'
                                );

                                return { Component: Assignments };
                            },
                            children: [
                                {
                                    id: 'examDetailsAssignment',
                                    errorElement: <ErrorPage />,
                                    path: `:assignmentId/${UrlSegments.edit}?`,
                                    async lazy() {
                                        const { Assignment } = await import(
                                            '~/components/teacher/InnerPage/Assignments/Assignment'
                                        );

                                        return { Component: Assignment };
                                    },
                                },
                            ],
                        },
                        {
                            id: 'examDetailsVariants',
                            errorElement: <ErrorPage />,
                            path: `variants/${UrlSegments.edit}?`,
                            async lazy() {
                                // TODO: Refactor based on the example of the `examDetailsGeneral` route, by moving
                                // the logic into a "Page"
                                const { Variants } = await import(
                                    '~/components/teacher/InnerPage/Variants/Variants'
                                );

                                return { Component: Variants };
                            },
                        },
                        {
                            id: 'examDetailsEvents',
                            errorElement: <ErrorPage />,
                            // Note: the details route events/:eventId is handled in the EventPage, check the route with the id 'eventPage'
                            path: `events/${UrlSegments.edit}?`,
                            async lazy() {
                                // TODO: Refactor based on the example of the `examDetailsGeneral` route, by moving
                                // the logic into a "Page"
                                const { EventsTable } = await import(
                                    '~/components/teacher/InnerPage/Events/EventsTable'
                                );

                                return { Component: EventsTable };
                            },
                        },
                    ],
                },
                // Routes for the exam passes used by students to take the exam
                {
                    id: 'examPassDetails',
                    path: 'exam-passes/:examPassId',
                    errorElement: <ErrorPage />,
                    async lazy() {
                        const [
                            { ExamPassDetailsPage },
                            { ExamPassDetailsPageLoader },
                        ] = await Promise.all([
                            import('./examPass/ExamPassDetailsPage'),
                            import(
                                '~/lib/router/loaders/ExamPassDetailsPageLoader'
                            ),
                        ]);

                        return {
                            loader: ExamPassDetailsPageLoader,
                            Component: ExamPassDetailsPage,
                        };
                    },
                    children: [
                        {
                            // Route to solely render virtual environments in an exam pass when
                            // there are no assignments. Otherwise the virtual environments are
                            // rendered as part of the assignments
                            id: 'examPassDetailsVirtualEnvironments',
                            errorElement: <ErrorPage />,
                            path: `virtual-environments`,
                            async lazy() {
                                const {
                                    ExamPassVirtualEnvironmentsPage,
                                } = await import(
                                    './examPass/virtualEnvironments/ExamPassVirtualEnvironmentsPage'
                                );

                                return {
                                    Component: ExamPassVirtualEnvironmentsPage,
                                };
                            },
                        },
                        {
                            id: 'examPassDetailsAssignments',
                            path: 'assignments',
                            async lazy() {
                                const {
                                    ExamPassDetailsAssignmentsPage,
                                } = await import(
                                    './examPass/assignments/ExamPassDetailsAssignmentsPage'
                                );

                                return {
                                    Component: ExamPassDetailsAssignmentsPage,
                                };
                            },
                            children: [
                                {
                                    id: 'examPassDetailsAssignment',
                                    path: `:assignmentId`,
                                    errorElement: <ErrorPage />,
                                    async lazy() {
                                        const {
                                            ExamPassDetailsAssignmentPage,
                                        } = await import(
                                            './examPass/assignments/ExamPassDetailsAssignmentPage'
                                        );

                                        return {
                                            Component: ExamPassDetailsAssignmentPage,
                                        };
                                    },
                                },
                            ],
                        },
                    ],
                },
                // Log Routes
                {
                    path: 'log',
                    async lazy() {
                        const { Log } = await import('./Log');

                        return { Component: Log };
                    },
                },
                // Export routes
                {
                    path: 'export',
                    async lazy() {
                        const { Export } = await import('./Export');

                        return { Component: Export };
                    },
                },
                // Legacy routes kept for managing url redirects.
                {
                    id: 'legacyCockpitPage',
                    path: 'cockpit',
                    async lazy() {
                        const { LegacyCockpitPage } = await import(
                            './events/LegacyCockpitPage'
                        );

                        return { Component: LegacyCockpitPage };
                    },
                },
                {
                    id: 'legacyExamDetail',
                    path: 'author/exam',
                    async lazy() {
                        const {
                            ExamDetailsPageLoader: EventPageLoader,
                        } = await import(
                            '~/lib/router/loaders/ExamDetailsPageLoader'
                        );

                        return { loader: EventPageLoader };
                    },
                },
                {
                    id: 'legacyExamPassDetailsPage',
                    path: 'exam',
                    async lazy() {
                        const { ExamPassDetailsPageLoader } = await import(
                            '~/lib/router/loaders/ExamPassDetailsPageLoader'
                        );

                        return {
                            loader: ExamPassDetailsPageLoader,
                        };
                    },
                },
                {
                    id: 'legacyCorrection',
                    path: 'correction',
                    async lazy() {
                        const { LegacyCorrectionPage } = await import(
                            './events/correction/LegacyCorrectionPage'
                        );

                        return { Component: LegacyCorrectionPage };
                    },
                },
                {
                    id: 'legacyResults',
                    path: 'results',
                    async lazy() {
                        const { LegacyResultsPage } = await import(
                            './events/LegacyResultsPage'
                        );

                        return { Component: LegacyResultsPage };
                    },
                },
            ],
        },
    ]);

    return <ReactRouterRouterProvider router={router} />;
}
