import { setDefaultOptions } from 'date-fns';
import { useEffect } from 'react';
import { dateFnsLocaleLoader } from './util/dateFnsLocaleLoader';

/**
 * This function sets the default locale for date-fns library using the provided locale code.
 *
 * @param {string} localeCode - The code of the locale to be set.
 */
export function useDateFnsDefaultLocale(localeCode: string) {
    useEffect(() => {
        const loadedLocale = dateFnsLocaleLoader(localeCode);

        if (loadedLocale) {
            setDefaultOptions({ locale: loadedLocale });
        }
    }, [localeCode]);
}
