import {
    DocumentNode,
    FieldNode,
    Kind,
    OperationDefinitionNode,
    parse,
} from 'graphql';
import type {
    Operation,
    OperationResult,
    SubscriptionForwarder,
    SubscriptionOperation,
} from 'urql';
import { make, toObservable } from 'wonka';
import { env } from './config';

export const forwardSubscription: SubscriptionForwarder = (
    fetchBody,
    operation
) => {
    return toObservable(createFetchSource(fetchBody, operation));
};

const getFieldSelections = (
    query: DocumentNode
): readonly FieldNode[] | null => {
    const node = query.definitions.find(
        (node: any): node is OperationDefinitionNode => {
            return node.kind === Kind.OPERATION_DEFINITION && node.name;
        }
    );

    return node !== undefined
        ? node.selectionSet.selections.filter(
              (node: any): node is FieldNode => {
                  return node.kind === Kind.FIELD && node.name;
              }
          )
        : null;
};

const createFetchSource = (
    fetchBody: SubscriptionOperation,
    operation: Operation
) => {
    return make<OperationResult>(({ next }) => {
        const abortController =
            typeof AbortController !== 'undefined'
                ? new AbortController()
                : undefined;

        const { context } = operation;

        const subscriptions: EventSource[] = [];

        const extraOptions =
            typeof context.fetchOptions === 'function'
                ? context.fetchOptions()
                : context.fetchOptions || {};

        const fetchOptions = {
            body: JSON.stringify(fetchBody),
            method: 'POST',
            ...extraOptions,
            headers: {
                'content-type': 'application/json',
                ...extraOptions.headers,
            },
            signal:
                abortController !== undefined
                    ? abortController.signal
                    : undefined,
        };

        void executeFetch(fetchBody, operation, fetchOptions).then((result) => {
            if (result !== undefined) {
                next(result);

                const fieldSelections = getFieldSelections(
                    parse(fetchBody.query)
                );

                fieldSelections.forEach((fieldSelection) => {
                    const selectionName = fieldSelection.name.value;

                    if (
                        result.data?.[selectionName]?.mercureUrl === undefined
                    ) {
                        return;
                    }

                    const mercureUrl = result.data[selectionName].mercureUrl;

                    // TODO: automatically add this to the request set, and strip it in result
                    if (process.env.NODE_ENV !== 'production' && !mercureUrl) {
                        throw new Error(
                            'Received a subscription response without mercureUrl. This will just return static data.'
                        );
                    }

                    const mercureSubscription = new EventSource(
                        mercureUrl.replace(
                            'http://127.0.0.1',
                            env.VITE_MERCURE_BASE_URL || window.location.origin
                        )
                    );

                    mercureSubscription.onmessage = (ev) => {
                        const newData = JSON.parse(ev.data);

                        result = {
                            ...result,
                            data: {
                                ...result.data,
                                [selectionName]: {
                                    ...result.data[selectionName],
                                    ...newData,
                                },
                            },
                        };

                        next(result);
                    };

                    subscriptions.push(mercureSubscription);
                });
            }
        });

        return () => {
            subscriptions.forEach((it) => it.close());

            if (abortController !== undefined) {
                abortController.abort();
            }
        };
    });
};

const executeFetch = async (
    _fetchBody: SubscriptionOperation,
    operation: Operation,
    opts: RequestInit
    // eslint-disable-next-line require-await
) => {
    const { url, fetch: fetcher } = operation.context;

    return (fetcher || fetch)(url, opts)
        .then((res) => {
            const { status } = res;
            const statusRangeEnd = opts.redirect === 'manual' ? 400 : 300;

            if (status < 200 || status >= statusRangeEnd) {
                throw new Error(res.statusText);
            } else {
                return res.json();
            }
        })
        .then((result) => result)
        .catch((err) => {
            if (err.name !== 'AbortError') {
                return { errors: [err] };
            }
        });
};
