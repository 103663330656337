import { Block } from '~/types/graphql';
import { sortBlocksByPosition } from './sortBlocksByPosition';

/**
 * Puts the given `blockToUpdate` to the new position in the list of `blocks`, updates
 * all `position` properties accordingly and returns a sorted array of blocks according
 * to their position
 */
export function updateBlockPosition(
    blockToUpdate: { id: string; position: number },
    blocks: Pick<Block, 'id' | 'position'>[]
) {
    const currentBlockId = blockToUpdate.id;
    const newPosition = blockToUpdate.position;

    const currentBlockIndex = blocks.findIndex(
        (block) => block.id === currentBlockId
    );

    const currentBlock = blocks[currentBlockIndex];
    let originalPosition = currentBlock.position;

    if (currentBlock.position === blockToUpdate.position) {
        // Most likely means there is something odd with the positions and that there are
        // now blocks having the same position value. This is the case when we mutate the cache without
        // querying all blocks again. So we clean this mess up.
        // Let's find what position is currently not assigned.
        const positions = blocks.map((block) => block.position);
        const validPositions = Array.from(
            { length: positions.length },
            (_, i) => i
        );
        const missingPosition = validPositions.find(
            (num) => !positions.includes(num)
        );

        if (missingPosition !== undefined) {
            // The position is missing indicates the block was moved, but the positions of the other blocks
            // are not up to date as we now have two blocks with the same position.
            // So this is our originalPosition therefore; where the block was before it was moved.
            originalPosition = missingPosition;
        } else if (currentBlockIndex !== currentBlock.position) {
            // There are no missing positions, but the block is not yet sorted by position in the blocks array.
            originalPosition = currentBlockIndex;
        } else {
            // There are no missing positions and the block's position is identical to its index in the array,
            // so we probably already sorted the blocks.
            return blocks;
        }
    }

    let updatedBlocks = [...blocks];

    if (newPosition > originalPosition) {
        updatedBlocks = updatedBlocks.map((block) => {
            if (block.id === currentBlockId) {
                return { ...block, position: newPosition };
            }

            if (
                block.position <= newPosition &&
                originalPosition < block.position
            ) {
                return { ...block, position: block.position - 1 };
            }

            return block;
        });
    } else if (newPosition < originalPosition) {
        updatedBlocks = updatedBlocks.map((block) => {
            if (block.id === currentBlockId) {
                return { ...block, position: newPosition };
            }

            if (
                block.position >= newPosition &&
                originalPosition > block.position
            ) {
                return { ...block, position: block.position + 1 };
            }

            return block;
        });
    }

    return sortBlocksByPosition(updatedBlocks);
}
