import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { useStoreState } from '~/store/hooks';
import { useDateFnsDefaultLocale } from '~/lib/useDateFnsDefaultLocale';
import de_DE from 'antd/lib/locale/de_DE';
import en_GB from 'antd/lib/locale/en_GB';
import fr_FR from 'antd/lib/locale/fr_FR';
import it_IT from 'antd/lib/locale/it_IT';

const languageModules: any = import.meta.glob(
    './../../../locales/compiled/app/messages/*.json',
    { eager: true }
);

interface LanguageWrapperProps {
    children: React.ReactNode;
}

export const LanguageWrapper = ({ children }: LanguageWrapperProps) => {
    const { locale } = useStoreState((state) => state.locale);
    useDateFnsDefaultLocale(locale);
    const [messages, setMessages] = useState({});

    useEffect(() => {
        for (const path in languageModules) {
            const fileName = path.match(/[^\\/]+$/)[0];
            if (fileName.toLowerCase() === `${locale}.json`) {
                setMessages(languageModules[path].default);
            }
        }
    }, [locale]);

    const getAntConfig = (locale: string) => {
        switch (locale) {
            case 'de':
                return de_DE;
            case 'fr':
                return fr_FR;
            case 'it':
                return it_IT;
            case 'en':
                return en_GB;
            default:
                console.error(`Unsupported locale: ${locale}`);
                return de_DE; // use de_DE as fallback locale
        }
    };

    return (
        <IntlProvider locale={locale} messages={messages}>
            <ConfigProvider locale={getAntConfig(locale)}>
                {children}
            </ConfigProvider>
        </IntlProvider>
    );
};
