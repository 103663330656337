import 'antd/dist/antd.css';
import { StoreProvider } from 'easy-peasy';
import { Provider, createClient } from 'urql';
import '~/assets/splitters.css';
import '~/assets/style.css';
import '~/components/CKEditor/style.css';
import ErrorBoundary from '~/components/ErrorBoundary';
import { clientOptions } from '~/lib/urql';
import { RouterProvider } from '~/routes/RouterProvider';
import store from '~/store/store';
import './App.css';
import { LanguageWrapper } from './shared/Layout/LanguageWrapper';

export const App = () => {
    const urqlClient = createClient({
        ...clientOptions,
    });

    return (
        <ErrorBoundary>
            <Provider value={urqlClient}>
                <StoreProvider store={store}>
                    <LanguageWrapper>
                        <RouterProvider />
                    </LanguageWrapper>
                </StoreProvider>
            </Provider>
        </ErrorBoundary>
    );
};
