import dashboardFilterModel, { DashboardFilterModel } from './dashboardFilter';
import localeModel, { LocaleModel } from './locale';
import { NotificationsModel, notificationsModel } from './notifications';
import studentModel, { StudentModel } from './student';

export interface StoreModel {
    dashboardFilter: DashboardFilterModel;
    locale: LocaleModel;
    student: StudentModel;
    notifications: NotificationsModel;
}

const storeModel: StoreModel = {
    dashboardFilter: dashboardFilterModel,
    locale: localeModel,
    student: studentModel,
    notifications: notificationsModel,
};

export default storeModel;
