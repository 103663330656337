import { BlockConnection } from '~/types/graphql';
import { BlockDataFragment } from '../graphql/BlockDataFragment.generated';
import { sortBlocksByPosition } from './sortBlocksByPosition';
import { getBlocksFromBlockConnection } from './getBlocksFromBlockConnection';
import { reassignBlocksInBlockConnection } from './reassignBlocksInBlockConnection';

export function updateBlockPositions(blockConnection: BlockConnection);
export function updateBlockPositions(blocks: BlockDataFragment[]);
/**
 * Goes through the given `blocks` and updates their position property to match their index in the array and sorts them.
 * Useful after deleting a block.
 * @param blocks
 * @returns
 */

export function updateBlockPositions<
    T extends BlockConnection | BlockDataFragment[]
>(blocksOrConnection: T): T {
    if ('edges' in blocksOrConnection) {
        const currentBlocks = getBlocksFromBlockConnection(blocksOrConnection);
        const blocks = sortBlocksByPosition(currentBlocks).map(
            (block, index) => ({
                ...block,
                position: index,
            })
        );

        return reassignBlocksInBlockConnection(blocks, blocksOrConnection) as T;
    } else if (
        'id' in blocksOrConnection &&
        Array.isArray(blocksOrConnection)
    ) {
        return sortBlocksByPosition(blocksOrConnection).map((block, index) => ({
            ...block,
            position: index,
        })) as T;
    }

    return blocksOrConnection;
}
