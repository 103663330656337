import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { SmlButton } from '~/components/ui/Buttons/Button';
import { SmlErrorState } from '~/components/ui/States/Error/ErrorState';
import { ApplicationError } from '~/lib/ApplicationError';

interface ErrorProps {
    error: ApplicationError | unknown;
}

const ErrorStatusCodes = [403, 404, 500, 'unknown'] as const;

/**
 * Component for showing application errors
 */
export function Error({ error: errorFromProps }: ErrorProps) {
    const intl = useIntl();

    let error: ApplicationError;
    let errorStatus: typeof ErrorStatusCodes[number] = 'unknown';

    if (!(errorFromProps instanceof ApplicationError)) {
        error = new ApplicationError({
            message: error?.message,
            errorMeta: errorFromProps,
        });
    } else {
        error = errorFromProps;
    }

    if (error.isUrqlCombinedError()) {
        const statusCodes = error.errorMeta?.graphQLErrors.map<number>(
            (error) => error.extensions.status as number
        );

        // As there can be multiple errors present at the same time,
        // prefer the most severe one
        if (statusCodes?.includes(500)) {
            errorStatus = 500;
        } else if (statusCodes?.includes(403)) {
            errorStatus = 403;
        } else if (statusCodes?.includes(404)) {
            errorStatus = 404;
        }
    }

    if (error.isRouteError()) {
        errorStatus = error.errorMeta?.status === 404 ? 404 : 'unknown';
    }

    switch (errorStatus) {
        case 403:
            error.message = 'app.error.403.title';
            error.description =
                error.description || 'app.error.403.description';
            break;
        case 404:
            error.message = 'app.error.404.title';
            error.description =
                error.description || 'app.error.404.description';
            break;
        case 500:
        default:
            error.message = 'app.error.500.title';
            error.description =
                error.description || 'app.error.500.description';
            break;
    }

    return (
        <SmlErrorState
            title={intl.formatMessage({
                id: error.message,
                defaultMessage: error.message,
            })}
            description={intl.formatMessage({
                id: error.description,
                defaultMessage: error.description,
            })}
        >
            <SmlErrorState.Actions>
                {(errorStatus === 404 || errorStatus === 403) && (
                    <>
                        <SmlButton variant="primary" href="/">
                            <FormattedMessage id="app.error.actions.backHome" />
                        </SmlButton>
                    </>
                )}

                {errorStatus === 500 && (
                    <>
                        <SmlButton
                            variant="primary"
                            onClick={() => window.location.reload()}
                        >
                            <FormattedMessage id="app.error.actions.retry" />
                        </SmlButton>
                        <SmlButton variant="secondary" href="/">
                            <FormattedMessage id="app.error.actions.backHome" />
                        </SmlButton>
                    </>
                )}
            </SmlErrorState.Actions>
        </SmlErrorState>
    );
}

/**
 * @deprecated Use `throw new ApplicationError(messange, errorMeta)` instead.
 */
export interface CustomErrorProps<T = unknown> {
    title: MessageDescriptor;
    errorMeta: T;
}
