import { isRouteErrorResponse, ErrorResponse } from 'react-router-dom';
import type { CombinedError } from 'urql';

/**
 * Smartlearn Application Error. Global error object.
 */
export class ApplicationError<T = unknown> extends Error {
    public description?: string;
    public errorMeta?: T;

    constructor(opts?: T | string) {
        let message = '';

        if (typeof opts === 'string') {
            message = opts;
        } else if (
            typeof opts === 'object' &&
            'message' in opts &&
            typeof opts.message === 'string'
        ) {
            message = opts.message;
        }

        super(message);

        this.name = this.constructor.name;

        if (typeof opts === 'object') {
            this.errorMeta = opts;
        }
    }

    /**
     * If true, the error is a urlq error.
     */
    isUrqlCombinedError(): this is ApplicationError<CombinedError> {
        return (
            (this.errorMeta as CombinedError)?.graphQLErrors !== undefined ||
            (this.errorMeta as CombinedError)?.networkError !== undefined
        );
    }

    /**
     * If true, the error is a react-dom-router error.
     */
    isRouteError(): this is ApplicationError<ErrorResponse> {
        return isRouteErrorResponse(this.errorMeta);
    }
}
