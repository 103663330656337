import { BlockConnection } from '~/types/graphql';
import { getBlocksFromBlockConnection } from './getBlocksFromBlockConnection';
import { reassignBlocksInBlockConnection } from './reassignBlocksInBlockConnection';
import { updateBlockPosition } from './updateBlockPosition';

/**
 * Puts the given `blockToUpdate` to the new position in the list of `blockConnection.blocks`, updates
 * all `position` properties accordingly and returns a BlockConnection with a sorted array of blocks according
 * to their position
 */

export function updateBlockPositionOnConnection(
    blockToUpdate: { id: string; position: number },
    blockConnection: BlockConnection
) {
    const currentBlocks = getBlocksFromBlockConnection(blockConnection);
    const blocks = updateBlockPosition(blockToUpdate, currentBlocks);

    const updatedBlockConnection = reassignBlocksInBlockConnection(
        blocks,
        blockConnection
    );

    return updatedBlockConnection;
}
