import { TypedBlockConnection } from '../types/blocks-util';

/**
 * Extract blocks from a block connection.
 * @param blockConnection
 * @returns
 */
export function getBlocksFromBlockConnection<TBlockData>(
    blockConnection: TypedBlockConnection<TBlockData>
): TBlockData[] {
    return blockConnection.edges.map((edge) => edge.node);
}
