import { SmlTypography } from '../../Typography/Typography';
import { Children, isValidElement } from 'react';
import classNames from 'classnames';
import classes from './ErrorState.module.css';
import { EnvironmentBlockedIcon, ErrorIcon } from '../../Icons';

export const smlErrorStateDecorationTypes = {
    default: 'default',
    environment: 'environment',
} as const;

export interface SmlErrorStateProps
    extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    description?: string;

    /**
     * Overwrite the default error decorator. For example, this replaces the decorative icon/illustration
     */
    decorator?: SmlErrorStateDecorationType;
}

export type SmlErrorStateDecorationType = typeof smlErrorStateDecorationTypes[keyof typeof smlErrorStateDecorationTypes];

/**
 * SmartLearn out of the box component to render error states.
 */
export function SmlErrorState({
    title,
    description,
    className,
    children,
    decorator = 'default',
    ...props
}: SmlErrorStateProps) {
    let actions: JSX.Element;

    Children.forEach(children, (child) => {
        if (!isValidElement(child)) {
            return;
        }

        switch (child.type) {
            case Actions:
                actions = child;
                break;
        }
    });

    let DecoratorFigure = () => <ErrorIcon />;

    if (decorator === 'environment') {
        DecoratorFigure = () => <EnvironmentBlockedIcon />;
    }

    return (
        <div
            {...props}
            className={classNames(classes.smlErrorState, className)}
            data-decorator={decorator}
        >
            {DecoratorFigure && (
                <div className={classes.smlErrorStateIllustration}>
                    <DecoratorFigure />
                </div>
            )}
            <SmlTypography
                className={classes.smlErrorStateTitle}
                tagName="h2"
                size="lg"
            >
                {title}
            </SmlTypography>
            {description && (
                <SmlTypography
                    className={classes.smlErrorStateDescription}
                    size="md"
                    emphasis="medium"
                >
                    {description}
                </SmlTypography>
            )}
            {actions}
        </div>
    );
}

SmlErrorState.Actions = Actions;

function Actions({ children }: React.HTMLAttributes<HTMLDivElement>) {
    return <div className={classes.smlErrorStateActions}>{children}</div>;
}
