// String conversion utilities

/**
 * Utility function to uncaptialize a sentence (make first letter lower case)
 */
export function uncapitalize<T extends string>(str: T): Uncapitalize<T> {
    return `${str.charAt(0).toLowerCase()}${str.substring(
        1
    )}` as Uncapitalize<T>;
}
