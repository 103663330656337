import classes from './Typography.module.css';
import classNames from 'classnames';

const SML_TYPOGRAPHY_SIZES = ['xs', 'sm', 'md', 'lg'] as const;
const SML_TYPOGRAPHY_EMPHASIS = ['low', 'medium', 'high'] as const;
const SML_TYPOGRAPHY_PRESETS = [
    'meta',
    'metaXs',
    'headingMd',
    'headingLg',
    'visuallyHidden',
] as const;

export interface SmlTypographyProps
    extends React.HTMLAttributes<HTMLOrSVGElement> {
    children?: React.ReactNode;

    /**
     * Instead of manual setting size and emphasis, you can set one of
     * the presets to use.
     * TODO: Update with typography presets from the new design system
     */
    preset?: typeof SML_TYPOGRAPHY_PRESETS[number];

    /**
     * Size of the text
     */
    size?: typeof SML_TYPOGRAPHY_SIZES[number];

    /**
     * Low emphasis means less important (less visible), high emphasis means important (more visible)
     */
    emphasis?: typeof SML_TYPOGRAPHY_EMPHASIS[number];

    /**
     * HTMLElement TagName which should be used to wrap the children. Default is `<p>`.
     * It's up to you to use the semantically correct element.
     */
    tagName?: keyof JSX.IntrinsicElements;
}

/**
 * SmartLearn Typography component, that allows to set the text style based on
 * presets or manually by emphasis and size.
 */
export const SmlTypography = ({
    tagName: TagName = 'p',
    size = 'md',
    emphasis = 'high',
    preset,
    children,
    className,
    ...props
}: SmlTypographyProps): JSX.Element => {
    const defaultCSSClasses = classNames(classes.smlTypography, {
        [classes[`smlTypographySize_${size}`]]: !preset && size,
        [classes[`smlTypographyEmphasis_${emphasis}`]]: !preset && emphasis,
        [classes[`smlTypographyPreset_${preset}`]]: preset,
    });

    className = classNames(defaultCSSClasses, className);

    return (
        <TagName className={className} {...props}>
            {children}
        </TagName>
    );
};
