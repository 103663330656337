import { Block, BlockConnection } from '~/types/graphql';

/**
 * Takes an array of blocks and a block connection, and replaces the nodes of the BlockConnection's edges with the given blocks,
 * based on the IDs.
 */

export function reassignBlocksInBlockConnection(
    blocks: Pick<Block, 'id'>[],
    blockConnection: BlockConnection
): BlockConnection {
    const edges: BlockConnection['edges'] = blocks.reduce(
        (accumulatedEdges, block) => {
            const edge = blockConnection.edges?.find(
                (edge) => edge.node?.id === block.id
            );

            if (edge) {
                return [
                    ...accumulatedEdges,
                    {
                        ...edge,
                        node: block,
                    },
                ];
            }

            return accumulatedEdges;
        },
        []
    );

    return {
        ...blockConnection,
        edges,
    };
}
