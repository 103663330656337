import { useRouteError } from 'react-router-dom';
import { Error } from '~/components/Errors/Error';

/**
 * Throwing an error somewhere in the application and not handling it ad hoc will cause this component to be used.
 * It currently handlers urql errors and any custom created errors.
 * @example ```js
 * const [{data, error}] = useQuery({...});
 * throw new ApplicationError(error.message, error);
 * ```
 * @example ```js
 * const [{data, error}] = useQuery({...});
 * throw new CombinedError(error);
 * ```
 */
export function ErrorPage() {
    const routeError = useRouteError();

    return <Error error={routeError} />;
}
