import type { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface Notification {
    type: 'info' | 'error' | 'success';
    title: string;
    text: string;

    /**
     * Alert style, currently only 'banner'
     * Might be extended later to allow other types of notifications like
     * 'badges' for a global notification center.
     */
    alerts: 'banner';

    /**
     * Is used to detect if the notification is visible to the user.
     * Notifications can be added and only made visible later.
     */
    visible?: boolean;

    /**
     * Unique identifier to allow duplication checks for the same notification
     */
    id: string;
}

/**
 * Model for global notifications
 */
export interface NotificationsModel {
    /**
     * All currently available notifications
     */
    items: Notification[];

    /**
     * Add or update a notification
     */
    set: Action<NotificationsModel, Notification>;

    /**
     * Remove a specific notification by its unique id
     */
    remove: Action<NotificationsModel, Notification['id']>;

    /**
     * Remove all notifications
     */
    clear: Action<NotificationsModel>;
}

export const notificationsModel: NotificationsModel = {
    items: [],
    set: action((state, payload) => {
        const indexToUpdate = payload?.id
            ? state.items.findIndex((item) => item.id === payload.id)
            : -1;

        if (indexToUpdate !== -1) {
            state.items[indexToUpdate] = {
                ...state.items[indexToUpdate],
                ...payload,
            };
        } else {
            state.items.push(payload);
        }
    }),
    remove: action((state, payload) => {
        const indexToRemove = state.items.findIndex(
            (item) => item.id === payload
        );

        if (indexToRemove) {
            state.items.splice(indexToRemove, 1);
        }
    }),
    clear: action((state) => {
        state.items = [];
    }),
};

export default notificationsModel;
