import classNames from 'classnames';
import classes from './LoadingSpinner.module.css';
import LoadingSpinnerIcon from './loading-circle-spinner.svg';

export type SmlLoadingSpinnerProps = {
    className?: string;
};

/**
 * SmlLoadingSpinner to show temporary state without specific progress information
 * @param props
 * @returns
 */
export const SmlLoadingSpinner = ({
    className,
}: SmlLoadingSpinnerProps): JSX.Element => {
    return (
        <div className={classNames(classes.smlLoadingSpinner, className)}>
            <LoadingSpinnerIcon vectorEffect="none" />
        </div>
    );
};
