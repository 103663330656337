import { action, Action } from 'easy-peasy';

export interface StudentModel {
    selectedAnswerId: string;
    selectAnswer: Action<StudentModel, string>;
    lastSubmittedExamId?: string;
    setLastSubmittedExamId: Action<StudentModel, string>;
}

const studentModel: StudentModel = {
    selectedAnswerId: '',
    selectAnswer: action((state, payload) => {
        state.selectedAnswerId = payload;
    }),
    setLastSubmittedExamId: action((state, payload) => {
        state.lastSubmittedExamId = payload;
    }),
};

export default studentModel;
