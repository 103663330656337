import type { Locale } from 'date-fns';
import { de, frCH, itCH, enGB } from 'date-fns/locale';

/**
 * This function loads the date-fns locale based on the provided locale code.
 *
 * @param locale Any locale definition string e.g. 'de', 'fr' or 'en'
 * @returns The date-fns locale object
 */
export function dateFnsLocaleLoader(localeCode: string): Locale {
    switch (localeCode) {
        case 'de':
            return de;
        case 'fr':
            return frCH;
        case 'it':
            return itCH;
        case 'en':
            return enGB;
        default:
            console.error(`Unsupported locale: ${localeCode}`);
            return de; // use DE as default locale
    }
}
