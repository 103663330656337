import { action, Action } from 'easy-peasy';

export interface LocaleModel {
    locale: string;
    changeLocale: Action<LocaleModel, string>;
}

const localeModel: LocaleModel = {
    locale: window.localStorage.getItem('smartlearn.locale') ?? 'de',
    changeLocale: action((state, payload) => {
        window.localStorage.setItem('smartlearn.locale', payload);
        document.documentElement.setAttribute('lang', payload);
        state.locale = payload;
    }),
};

export default localeModel;
