import type { ExamType } from '~/routes/dashboard/Exams';
import { UserFavouritesCountFragment } from './graphql/UserFavouritesCountFragment.generated';
import { useMemo } from 'react';

interface UserFavouritesCountProps {
    user: UserFavouritesCountFragment;
    filter?: {
        examType?: ExamType;

        /**
         * Exclude those types
         * If include and exclude are both defined, include takes presence
         */
        exclude?: ('passes' | 'examEvents' | 'exams')[] | null;

        /**
         * Include those types only.
         * If include and exclude are both defined, include takes presence
         */
        include?: ('passes' | 'examEvents' | 'exams')[] | null;
    };
}

/**
 * Hook to make counting favourites easier.
 * This currently takes into considerations limitations of the cache and the API. It also filters long running items locally,
 * which is totally optional but is helping using the urql cache.
 * @returns
 */
export function useUserFavouritesCount({
    user,
    filter,
}: UserFavouritesCountProps) {
    const favouriteExamEvents = user?.favouriteExamEvents;
    const favouriteExamPasses = user?.favouriteExamPasses;
    const favouriteExams = user?.favouriteExams;

    const favouritesCount = useMemo(() => {
        let count = 0;

        let favouriteExamEventsCount = 0;
        let favouriteExamsCount = 0;
        let favouritePassesCount = 0;

        if (filter?.examType !== undefined && filter?.examType !== 'all') {
            const isLongRunning = filter.examType === 'learning';

            favouriteExamsCount =
                favouriteExams?.edges?.filter(
                    (exam) =>
                        exam?.node && exam.node?.isLongRunning === isLongRunning
                )?.length || 0;

            favouriteExamEventsCount =
                favouriteExamEvents?.edges.filter(
                    (event) =>
                        event?.node &&
                        event.node?.examVariant?.isLongRunning === isLongRunning
                )?.length || 0;

            favouritePassesCount =
                favouriteExamPasses?.filter(
                    (pass) =>
                        pass.event.examVariant.isLongRunning === isLongRunning
                )?.length || 0;
        } else {
            favouriteExamEventsCount += favouriteExamEvents?.totalCount || 0;
            favouriteExamsCount += favouriteExams?.totalCount || 0;
            favouritePassesCount += favouriteExamPasses?.length || 0;
        }

        if (
            filter?.include?.includes('passes') ||
            (!filter?.include && !filter?.exclude?.includes('passes'))
        ) {
            count += favouritePassesCount;
        }

        if (
            filter?.include?.includes('exams') ||
            (!filter?.include && !filter?.exclude?.includes('exams'))
        ) {
            count += favouriteExamsCount;
        }

        if (
            filter?.include?.includes('examEvents') ||
            (!filter?.include && !filter?.exclude?.includes('examEvents'))
        ) {
            count += favouriteExamEventsCount;
        }

        return count;
    }, [
        filter?.examType,
        filter?.include,
        filter?.exclude,
        favouriteExams?.edges,
        favouriteExams?.totalCount,
        favouriteExamEvents?.edges,
        favouriteExamEvents?.totalCount,
        favouriteExamPasses,
    ]);

    return favouritesCount;
}
